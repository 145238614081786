import { LangDefinition } from '@jsverse/transloco';

import { LanguageCode } from './language-code';

/**
 * List of default languages configured with transloco.
 */
export const DEFAULT_AVAILABLE_LANGUAGES: LangDefinition[] = [
	{ id: LanguageCode.English, label: 'English' },
	{ id: LanguageCode.German, label: 'Deutsch' },
	{ id: LanguageCode.Spanish, label: 'Español' },
	{ id: LanguageCode.French, label: 'Français' },
	{ id: LanguageCode.Italian, label: 'Italiano' },
	{ id: LanguageCode.Portuguese, label: 'Portuguese (Brazil)' },
	{ id: LanguageCode.Russian, label: 'Pусский' },
	{ id: LanguageCode.ChineseSimple, label: '中文（简体) ' },
	{ id: LanguageCode.ChineseTraditional, label: '中國（傳統的) ' },
	{ id: LanguageCode.Japanese, label: '日本語' },
	{ id: LanguageCode.Korean, label: '한국어' },
	{ id: LanguageCode.Arabic, label: 'العربية' }
];
