import { LogLevel } from './models';

/**
 * Interface to enable access to the LoggerService.
 * Should be consumed via DI in client class and create new Logger instance.
 */
export abstract class ILogger {
	/**
	 * An identifier for where the logger is created
	 */
	protected scope = '';

	/**
	 * Creates an Logger instance
	 */
	public abstract createScopedLogger(scope?: string): ILogger;

	/**
	 * Submits log entries
	 * @ignore This method is only public for testing puorposes
	 */
	public abstract log(level: LogLevel, eventId: string, message: string, args?: unknown): void;

	/**
	 * Logs events of unrecoverable application or system crash,
	 * or a catastrophic failure that requires immediate attention.
	 */
	public critical(eventId: string, message: string, args?: unknown): void {
		this.log(LogLevel.Critical, eventId, message, args);
	}

	/**
	 * Logs events to track the general flow of the application.
	 * These logs should have long-term value.
	 */
	public debug(eventId: string, message: string, args?: unknown): void {
		this.log(LogLevel.Debug, eventId, message, args);
	}

	/**
	 * Logs events that highlight when the current flow of execution is stopped due to a failure.
	 * These should indicate a failure in the current activity, not an application-wide failure.
	 */
	public error(eventId: string, message: string, args?: unknown): void {
		this.log(LogLevel.Error, eventId, message, args);
	}

	/**
	 * Logs events to track the general flow of the application.
	 * These logs should have long-term value.
	 */
	public information(eventId: string, message: string, args?: unknown): void {
		this.log(LogLevel.Information, eventId, message, args);
	}

	/**
	 * Logs events containing the most detailed messages.
	 * These messages may contain sensitive application data.
	 * These messages are disabled by default and should never be enabled in a production environment.
	 */
	public trace(eventId: string, message: string, args?: unknown): void {
		this.log(LogLevel.Trace, eventId, message, args);
	}

	/**
	 * Logs that highlight an abnormal or unexpected event in the application flow,
	 * but do not otherwise cause the application execution to stop.
	 */
	public warning(eventId: string, message: string, args?: unknown): void {
		this.log(LogLevel.Warning, eventId, message, args);
	}
}
