import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { LoggingConfig } from '../models';

import { ILoggingConfigProvider, LOGGING_CONFIG } from './logging-config.provider';

@Injectable()
export class StaticLoggingConfigProvider implements ILoggingConfigProvider {
	constructor(@Inject(LOGGING_CONFIG) private loggingConfig: LoggingConfig) {}

	public get config$(): Observable<LoggingConfig> {
		return of(this.loggingConfig);
	}
}
