/**
 * This method is called to shuffle background colors based on the hash of the email.
 * @param email - email id of logged in user
 * @returns color index calculated from the email hash
 */
export function getEmailColorIndex(email = ''): number {
	let hash = 0,
		i,
		chr;
	if (email.length !== 0) {
		for (i = 0; i < email.length; i++) {
			chr = email.charCodeAt(i);
			hash = (hash << 5) - hash + chr;
			hash |= 0; // Convert to 32bit integer
		}
	}

	/**
	 * To ensure the colorIndex is always positive, 7 is added to it and a modulus operation is performed
	 * when the colorIndex is negative.
	 */
	let colorIndex = hash % 7;
	if (colorIndex < 0) {
		colorIndex = (colorIndex + 7) % 7;
	}
	return colorIndex;
}

/**
 * This method is called to set background colors based on the hash of the name.
 * @param name - can be any name
 * @returns color index calculated from the email hash
 */
export function generateColorFromName(name: string): string {
	let hash = 0;
	for (let i = 0; i < name.length; i++) {
		hash = name.charCodeAt(i) + ((hash << 5) - hash);
	}
	// Ensure the color index is positive and between 0 and 6 (using % 7)
	let colorIndex = hash % 7;
	if (colorIndex < 0) {
		colorIndex = (colorIndex + 7) % 7;
	}
	// Generate a hex color from the hash
	let color = '#';
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff;
		color += ('00' + value.toString(16)).slice(-2);
	}
	return color;
}
