<div
	id="sh-device-state-bar"
	class="sh-device-state"
	[matTooltip]="deviceStateTooltip"
	[ngClass]="{
		'sh-device-good': state === deviceStateEnum.Online,
		'sh-device-inactive': state === deviceStateEnum.Offline,
		'sh-device-ok': state === deviceStateEnum.Discovered,
		'sh-device-bad': state === deviceStateEnum.Error,
		'sh-device-unknown': state === deviceStateEnum.Unknown
	}"
></div>
