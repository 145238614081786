<h2 mat-dialog-title class="sh-info-dialog-title">
	<mat-icon *ngIf="data.showWarningIcon" class="sh-warning-icon" fontIcon="sh-warning-outline"> </mat-icon>
	{{ data.title }}
</h2>
<mat-dialog-content>
	<div mat-dialog-content class="sh-info-dialog-body">
		<span *ngFor="let line of data.body; let i = index" class="mat-body-2" [innerHtml]="line"></span>
	</div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end end">
	<button cdkFocusInitial color="accent" mat-button id="sh-info-dialog-ok-button" (click)="close()">
		{{ data.okLabel }}
	</button>
</mat-dialog-actions>
