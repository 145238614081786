import { Inject, Injectable } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';

import { HtmlSnackbarComponent } from './html-snackbar.component';

export enum CloseTextOption {
	Ok = 'OK',
	Dismiss = 'Dismiss'
}

@Injectable({ providedIn: 'root' })
export class SnackbarService {
	private readonly defaultSnackbarConfig: MatSnackBarConfig = {
		...this.snackbarConfig,
		panelClass: ['sh-center-snackbar', 'mat-toolbar', 'mat-primary']
	};

	constructor(
		private readonly snackbar: MatSnackBar,
		private readonly transloco: TranslocoService,
		@Inject(MAT_SNACK_BAR_DEFAULT_OPTIONS) private readonly snackbarConfig: MatSnackBarConfig
	) {}

	/**
	 * Open snackbar with custom message, custom dismiss text and optional custom configs
	 */
	public open(message: string, closeText: CloseTextOption, configOptions?: MatSnackBarConfig): void {
		this.snackbar.openFromComponent(HtmlSnackbarComponent, {
			data: {
				message,
				dismissText:
					closeText === CloseTextOption.Ok
						? this.transloco.translate('common.snackbar.ok')
						: this.transloco.translate('common.snackbar.dismiss')
			},
			...this.defaultSnackbarConfig,
			...configOptions
		});
	}
}
