/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AnalyticsAppInfo, AnalyticsSuperProperties } from '../models';

@Injectable()
export class AnalyticsService {
	private appInfo!: AnalyticsAppInfo;
	private isAnalyticsOpted = false;
	public mixPanel: Mixpanel;
	constructor(private deviceService: DeviceDetectorService) {
		this.mixPanel = mixpanel;
	}

	/**
	 * Set app name and version information for analytics
	 */
	public setApplicationInfo(appInfo: AnalyticsAppInfo): void {
		this.appInfo = appInfo;
	}

	/**
	 * Set the analytic data from application
	 * @param event - String representation of event id
	 * @param data - DataObjectType representation of data from event
	 */
	public analyticDataCollector(event: string, data: object): void {
		this.submitToAnalyticsPlatform(event, data);
	}

	/**
	 * Sets the analytics opt in status
	 */
	public setOptIn(): void {
		this.isAnalyticsOpted = true;
	}

	/**
	 *  Sets the analytics opt out status
	 */
	public setOptOut(): void {
		this.isAnalyticsOpted = false;
	}

	/**
	 * Submit the Analytic data
	 * @param event - String representation of event id
	 * @param data - DataObjectType representation of data from event
	 */
	public submitToAnalyticsPlatform(event: string, data: object): void {
		if (this.isAnalyticsOpted) {
			const eventData = {
				...data,
				...this.getSuperProperties()
			};
			this.mixPanel.track(event, eventData);
		}
	}

	/**
	 * Gets the super or common properties of an analytics event
	 */
	private getSuperProperties(): AnalyticsSuperProperties {
		const oktaTokenStorage = JSON.parse(window.localStorage.getItem('okta-token-storage') || '{}');
		return {
			...this.appInfo,
			distinct_id: oktaTokenStorage.idToken ? oktaTokenStorage.idToken.claims.sub : null,
			session_id: oktaTokenStorage.idToken ? oktaTokenStorage.idToken.claims.jti : null,
			browser_name: `${this.deviceService.browser}`,
			browser_version: `${this.deviceService.browser_version}`,
			os_version: this.deviceService.os_version,
			os_product: this.deviceService.os,
			os_manufacturer: this.getManufacturerName(),
			timestamp: Date.now(),
			country: oktaTokenStorage.idToken ? oktaTokenStorage.idToken.claims.Custom_Country : null
		};
	}

	/**
	 * Gets the os manufacturer name
	 */
	private getManufacturerName(): string {
		const os = this.deviceService.os;
		switch (os) {
			case 'win32':
				return 'Windows';
			case 'Mac':
				return 'Apple';
			default:
				return `Other operating system ${os}`;
		}
	}
}
