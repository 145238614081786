import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { LoggingConfig } from '../models';

export const LOGGING_CONFIG = new InjectionToken<LoggingConfig>('LOGGING_CONFIG');

export abstract class ILoggingConfigProvider {
	/**
	 * Provides the logging configuration of the applicaiton.
	 */
	public abstract config$: Observable<LoggingConfig>;
}
