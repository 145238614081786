import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { first, map, Observable } from 'rxjs';

import { extractLabels } from './extractLabels';

@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(private readonly http: HttpClient) {}

	public getTranslation(langPath: string): Observable<Translation> {
		const labels = this.http.get<Translation>(`./assets/i18n/${langPath}.json`).pipe(
			first(),
			map((labels) => extractLabels(labels))
		);

		return labels;
	}
}
