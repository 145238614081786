import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';

import { SearchBarComponent } from './search-bar.component';

@NgModule({
	declarations: [SearchBarComponent],
	exports: [SearchBarComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, FormsModule, ReactiveFormsModule, TranslocoModule]
})
export class SearchBarModule {}
