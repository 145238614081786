import { TranslocoService } from '@jsverse/transloco';
import {
	ColDef,
	GridApi,
	GridOptions,
	GridReadyEvent,
	SizeColumnsToContentStrategy,
	SizeColumnsToFitGridStrategy,
	SizeColumnsToFitProvidedWidthStrategy
} from 'ag-grid-community';

import { IconButtonParams, IconButtonRendererComponent } from '../../cell-renderers/icon-button-renderer';
import { ipAddressComparator, macAddressComparator } from '../../utils/comparators';
import { NoRowsOverlayComponent } from '../overlays/no-rows-overlay/no-rows-overlay.component';

export type AutoSizeStrategy =
	| SizeColumnsToFitGridStrategy
	| SizeColumnsToFitProvidedWidthStrategy
	| SizeColumnsToContentStrategy;

export type TextColumnOptions<TRow> = ColDef<TRow> & Required<Pick<ColDef<TRow>, 'field' | 'headerName'>>;

export type CustomRendererColumnOptions<TRow> = ColDef<TRow> & Required<Pick<ColDef<TRow>, 'cellRenderer'>>;

export type IconButtonColumnOptions<TRow> = ColDef<TRow> & {
	iconButtonParams: IconButtonParams;
};

export abstract class BaseTableComponent<TRow> {
	/**
	 * Standard grid options
	 * https://ag-grid.com/angular-data-grid/grid-options/#reference-gridOptions-gridOptions
	 */
	public readonly gridOptions: GridOptions<TRow>;

	public gridApi?: GridApi<TRow>;

	constructor(protected readonly transloco: TranslocoService) {
		this.gridOptions = this.getGridOptions();
	}

	public onGridReady(params: GridReadyEvent<TRow>): void {
		this.gridApi = params.api;

		// During dev/testing, I noticed that when columns were removed the remaining columns would not
		// automatically resize proportionally unless I had first clicked on the "Reset columns" from the column menu.
		// By calling resetColumnStage once, when the grid is ready, and after a tick (hence the setTimeout()),
		// the resizing works as expected.
		setTimeout(() => {
			if (!this.gridApi?.isDestroyed()) {
				this.gridApi?.resetColumnState();
			}
		});
	}

	protected getGridOptions(): GridOptions<TRow> {
		return {
			/**
			 * Column definition applied to columns in a grouping row
			 * https://ag-grid.com/angular-data-grid/grid-options/#reference-rowGrouping-autoGroupColumnDef
			 */
			autoGroupColumnDef: {
				minWidth: 200
			},
			/**
			 * Default strategy used for autosizing grid columns + some additional padding
			 * https://ag-grid.com/angular-data-grid/grid-options/#reference-columnSizing-autoSizeStrategy
			 */
			autoSizeStrategy: { type: 'fitGridWidth' },
			autoSizePadding: 24,
			/**
			 * Default column definition for every column in the grid
			 * https://ag-grid.com/angular-data-grid/grid-options/#reference-columns-defaultColDef
			 */
			defaultColDef: {
				minWidth: 125,
				wrapHeaderText: true,
				autoHeaderHeight: true,
				flex: 1, // by default, we want all columns to resize proportionally. This can be overridden.
				cellStyle: { display: 'block' } // ag-grid "feauture" that ensures ellipsis are used when column overflow
			},
			/**
			 * Get a translated grid string
			 * https://ag-grid.com/angular-data-grid/grid-options/#reference-localisation-getLocaleText
			 */
			getLocaleText: (params) => this.transloco.translate(`ag-grid.${params.key}`),
			headerHeight: 36,
			noRowsOverlayComponent: NoRowsOverlayComponent,
			noRowsOverlayComponentParams: {
				secondaryMessage: 'ag-grid.noRowsToShow'
			},
			/**
			 * Setting popup parent to the body is necessary for column menu popups to extend outside of the table when
			 * the table is not tall enough to contain the popup.
			 * https://ag-grid.com/angular-data-grid/grid-options/#reference-accessories-popupParent
			 */
			popupParent: document.querySelector('body'),
			rowHeight: 36,
			rowSelection: 'multiple',
			suppressCellFocus: true,
			suppressContextMenu: true,
			suppressCsvExport: true,
			suppressExcelExport: true,
			suppressMenuHide: true
		};
	}

	/**
	 * Creates a column definition for a checkbox selection column with common defaults
	 */
	protected createCheckboxColumn(options: Partial<ColDef<TRow>> = {}): ColDef<TRow> {
		return {
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
			suppressHeaderMenuButton: true,
			suppressColumnsToolPanel: true, // don't want this column showing up in menus
			suppressMovable: true,
			sortable: false,
			filter: false,
			resizable: false,
			minWidth: 42,
			maxWidth: 42,
			pinned: 'left',
			lockPinned: true,
			lockPosition: true,
			flex: 0,
			...options
		};
	}

	/**
	 * Creates a column definition for a basic text column with sorting and filtering enabled by default
	 */
	protected createTextColumn(options: TextColumnOptions<TRow>): ColDef<TRow> {
		return {
			sortable: true,
			filter: true,
			...options
		};
	}

	/**
	 * Creates a text column for an IP address field
	 */
	protected createIpAddressColumn(options: Omit<TextColumnOptions<TRow>, 'headerName'>): ColDef<TRow> {
		return {
			headerName: this.transloco.translate('cloud.device-management.inventory.ip-address'),
			comparator: (a?: string, b?: string) => ipAddressComparator(a, b),
			...options
		};
	}

	/**
	 * Creates a text column for a serial number field
	 */
	protected createSerialNumberColumn(options: Omit<TextColumnOptions<TRow>, 'headerName'>): ColDef<TRow> {
		return {
			headerName: this.transloco.translate('cloud.device-management.inventory.serial-number'),
			enableRowGroup: false,
			getQuickFilterText: (params) => params.value?.toLowerCase() ?? '',
			...options
		};
	}

	/**
	 * Creates a text column for a MAC address field
	 */
	protected createMacAddressColumn(options: Omit<TextColumnOptions<TRow>, 'headerName'>): ColDef<TRow> {
		return {
			headerName: this.transloco.translate('cloud.device-management.inventory.mac-address'),
			enableRowGroup: false,
			comparator: (a?: string, b?: string) => macAddressComparator(a, b),
			getQuickFilterText: (params) => params.value?.toLowerCase() ?? '',
			...options
		};
	}

	/**
	 * Creates a column definition for a column with a custom cell renderer
	 */
	protected createCustomRendererColumn(options: CustomRendererColumnOptions<TRow>): ColDef<TRow> {
		return {
			cellDataType: false,
			...options
		};
	}

	/**
	 * Creates a column definition for a column that uses the custom icon button cell renderer
	 */
	protected createIconButtonColumn(options: IconButtonColumnOptions<TRow>): ColDef<TRow> {
		return {
			cellRenderer: IconButtonRendererComponent,
			cellRendererParams: options.iconButtonParams,
			...options
		};
	}
}
