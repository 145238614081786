export enum AnalyticsEvents {
	OptInState = 'opt_in_state',
	AppClicked = 'app_clicked',
	HelpRequested = 'help_requested',
	SignIn = 'sign_in',
	SignOut = 'sign_out',
	AppTabSet = 'app_tab_set',
	LicenseGroupDetails = 'license_group_details',
	LicensesMoved = 'licenses_moved',
	DeviceDecommissioned = 'device_decommissioned'
}
