import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';

import { ConfirmDialogData } from './confirm-dialog.component.models';

@Component({
	selector: 'sh-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatDialogModule,
		MatCardModule,
		TranslocoModule,
		MatButtonModule,
		MatCheckboxModule,
		MatIconModule
	]
})
export class ConfirmDialogComponent {
	public doNotShowDialog = false;
	constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ConfirmDialogData) {}

	public toggleDoNotShowDialog(doNotShowDialog: boolean): void {
		this.doNotShowDialog = doNotShowDialog;
	}
}
