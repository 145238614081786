import { Component } from '@angular/core';

/**
 * ```<sh-loader>``` is a loader component used to show the loading symbol
 */
@Component({
	selector: 'sh-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {}
