<div id="sh-inactivity-warning-dialog-container-div" *transloco="let t">
	<h3 id="sh-inacitivity-dialog-title-h3">{{ t('cloud.shared.session-inactivity.warning-timeout-title') }}</h3>
	<div id="sh-inacitivity-dialog-content-div" mat-dialog-content>
		<div>
			{{
				t('cloud.shared.session-inactivity.warning-timeout-message', {
					value: inactivityService.secondsUntilTimeout$ | async
				})
			}}
		</div>
		<br />
		<div id="sh-inactivity-dialog-button-group-div">
			<button
				mat-button
				[mat-dialog-close]="inactivityDialogSelection.SignOutNow"
				class="sh-inacitivity-dialog-inactivity-action"
				id="sh-inactivity-dialog-sign-out-now-button"
			>
				{{ t('cloud.shared.session-inactivity.sign-out-now') }}
			</button>
			<button
				mat-button
				[mat-dialog-close]="inactivityDialogSelection.RemainSignedIn"
				class="sh-inacitivity-dialog-inactivity-action"
				id="sh-inacitivity-dialog-remain-signed-in-button"
			>
				{{ t('cloud.shared.session-inactivity.remain-signed-in') }}
			</button>
		</div>
	</div>
</div>
