import { Component } from '@angular/core';

import { InactivityDialogSelection } from '../models/inactivity-dialog.model';
import { InactivityService } from '../services/inactivity.service';

@Component({
	selector: 'sh-inactivity-dialog',
	templateUrl: './inactivity-dialog.component.html',
	styleUrls: ['./inactivity-dialog.component.scss']
})
export class InactivityDialogComponent {
	// this is so the enum value can be used in the HTML
	public inactivityDialogSelection = InactivityDialogSelection;

	constructor(public inactivityService: InactivityService) {}
}
