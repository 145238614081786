import { DeviceState } from '@shure/cloud/shared/models/devices';
import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

import { NameCellData } from './device-name-renderer.component';

export function areNameCellsEqual(a?: NameCellData, b?: NameCellData): boolean {
	const aData = a ?? getDefaultCellData();
	const bData = b ?? getDefaultCellData();

	return (
		aData.id === bData.id &&
		aData.name === bData.name &&
		aData.model === bData.model &&
		aData.deviceState === bData.deviceState &&
		aData.isVirtual === bData.isVirtual &&
		aData.canIdentify === bData.canIdentify &&
		aData.isIdentifying === bData.isIdentifying &&
		aData.isDisabled === bData.isDisabled
	);
}

function getDefaultCellData(): NameCellData {
	return {
		id: '',
		name: '',
		model: DeviceModel.AD600,
		deviceState: DeviceState.Offline,
		isVirtual: false,
		canIdentify: false,
		isIdentifying: false,
		isDisabled: false
	};
}
