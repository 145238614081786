/* eslint-disable no-console*/
import { Injectable } from '@angular/core';

import { LogEntry, LogLevel } from '../models';

import { LoggerSink } from './logger.sink';

@Injectable()
export class ConsoleLoggerSink implements LoggerSink {
	public readonly name = 'ConsoleLogger';

	public log(logEntry: LogEntry): void {
		const message = `[${logEntry.scope}] ${logEntry.eventId} ‣ ${logEntry.message}`;
		switch (logEntry.level) {
			case LogLevel.Critical:
			case LogLevel.Error:
				console.error(message, logEntry.args || '');
				break;
			case LogLevel.Warning:
				console.warn(message, logEntry.args || '');
				break;
			case LogLevel.Information:
				// eslint-disable-next-line no-restricted-syntax
				console.info(`ℹ️ ${message}`, logEntry.args || '');
				break;
			case LogLevel.Debug:
				console.log(`%c🐛 ${message}`, 'color: #68a2ff', logEntry.args || '');
				break;
			case LogLevel.Trace:
				console.log(`%c🦄 ${message}`, 'color: #76797c', logEntry.args || '');
				break;
		}
	}
}
