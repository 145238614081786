export enum DeviceCompatibilityLevel {
	CompatibleLatest = 'COMPATIBLE_LATEST',
	CompatibleNew = 'COMPATIBLE_NEW',
	CompatibleOld = 'COMPATIBLE_OLD',
	IncompatibleTooNew = 'INCOMPATIBLE_TOO_NEW',
	IncompatibleTooOld = 'INCOMPATIBLE_TOO_OLD'
}

const INCOMPATIBLE_COMPATIVILITY_LEVELS = new Set([
	DeviceCompatibilityLevel.IncompatibleTooNew,
	DeviceCompatibilityLevel.IncompatibleTooOld
]);

export function isFirmwareIncompatible(compatibitly: DeviceCompatibilityLevel): boolean {
	return INCOMPATIBLE_COMPATIVILITY_LEVELS.has(compatibitly);
}
