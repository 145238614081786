import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AgGridModule, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { InventoryDevice } from '@shure/cloud/shared/models/devices';

export interface IconButtonParams {
	icon: string;
	onClick(deviceId: string): void;
}

export type IconButtonRendererComponentParamsType = ICellRendererParams<InventoryDevice> & IconButtonParams;

/**
 * ```sh-icon-button-renderer``` is a view component for displaying an icon button in a cell
 */
@Component({
	selector: 'sh-icon-button-renderer',
	templateUrl: './icon-button-renderer.component.html',
	styleUrls: ['./icon-button-renderer.component.scss'],
	standalone: true,
	imports: [CommonModule, AgGridModule, MatButtonModule, MatIconModule, TranslocoModule]
})
export class IconButtonRendererComponent implements ICellRendererAngularComp {
	public params!: IconButtonRendererComponentParamsType;

	constructor(public readonly transloco: TranslocoService) {}

	public agInit(params: IconButtonRendererComponentParamsType): void {
		this.update(params);
	}

	public refresh(params: IconButtonRendererComponentParamsType): boolean {
		this.update(params);
		return true;
	}

	public onClick(): void {
		if (!this.params.data) {
			return;
		}

		this.params.onClick(this.params.data.id);
	}

	private update(params: IconButtonRendererComponentParamsType): void {
		this.params = params;
	}
}
