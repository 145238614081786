/**
 * NOTE:
 * Also change list of all languages in: tools/scripts/transloco/utils.ts if you change this
 */
/**
 * Supported language codes
 */
export enum LanguageCode {
	Arabic = 'ar',
	ChineseSimple = 'zh-cn',
	ChineseTraditional = 'zh-tw',
	English = 'en',
	French = 'fr',
	German = 'de',
	Italian = 'it',
	Japanese = 'ja',
	Korean = 'ko',
	Portuguese = 'pt',
	Russian = 'ru',
	Spanish = 'es'
}
