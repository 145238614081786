<ng-container *transloco="let t">
	<h2 mat-dialog-title>
		<mat-icon *ngIf="data.showWarningIcon" class="sh-warning-icon" fontIcon="sh-warning-outline"></mat-icon>
		<span>{{ data.title }}</span>
	</h2>

	<div id="sh-mat-dialog-div">
		<mat-dialog-content>
			<div class="sh-mat-dialog-content">
				<span *ngFor="let line of data.body; let i = index" class="mat-body-2" [innerHtml]="line"></span>
				<span *ngIf="data.doNotShowDialogId"
					><mat-checkbox
						id="sh-confirm-dialog-do-not-show"
						(change)="toggleDoNotShowDialog($event.checked)"
						[checked]="doNotShowDialog"
					>
						{{ t('common.labels.no-show-dialog-again') }}
					</mat-checkbox></span
				>
			</div>
		</mat-dialog-content>

		<mat-dialog-actions class="sh-mat-dialog-actions">
			<button
				mat-button
				id="sh-confirm-dialog-cancel-button"
				color="accent"
				[mat-dialog-close]="{ confirmed: false, doNotShowDialog }"
			>
				{{ data.cancelLabel }}
			</button>
			<button
				cdkFocusInitial
				color="accent"
				mat-button
				id="sh-confirm-dialog-confirm-button"
				[mat-dialog-close]="{ confirmed: true, doNotShowDialog }"
				[disabled]="data.disableConfirmButton"
			>
				{{ data.confirmLabel }}
			</button>
		</mat-dialog-actions>
	</div>
</ng-container>
