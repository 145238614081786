import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class MatchValidator {
	/**
	 * Intended to be supplied as the validator on a formGroup. Will compare the values of 2 controls within that formgroup,
	 * and will set/overwrite, the errors on the target control, when the comparison of the values are different.
	 * @param source this is the name of the first control
	 * @param target  this is the name of the second control
	 * @param isCaseSensitive
	 * @returns
	 */
	public static matches(source: string, target: string, isCaseSensitive = true): ValidatorFn {
		return (group: AbstractControl): ValidationErrors | null => {
			const sourceValue = group?.get(source)?.value;
			const targetValue = group?.get(target)?.value;

			let isMatch: boolean;
			if (isCaseSensitive) {
				isMatch = sourceValue === targetValue;
			} else {
				isMatch = sourceValue.toLowerCase() === targetValue.toLowerCase();
			}

			const errors: ValidationErrors | null = isMatch ? null : { mismatch: true };

			group?.get(target)?.setErrors(errors);

			return errors;
		};
	}
}
